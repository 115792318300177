import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";



function Login() {
  const [signUpChecked, setSignUpChecked] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const nav = useNavigate();
  
  function handleSignupCheckbox(event) {
    // event.preventDefault();
    setSignUpChecked(event.target.checked);
    console.log(signUpChecked)
  }

  function handleSubmit(event) {
    event.preventDefault();
  
    const data = { username, password };
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };

    var uri;

    if(signUpChecked) {
      uri = "http://localhost:3001/signup"
    } else {
      uri = "http://localhost:3001/login"
    }
    fetch(uri, options)
      .then((response) => response.json())
      .then((result) => {
        
        nav("/collections")
        // handle login success or failure here
      })
      .catch((error) => {
        // handle error here
      });
  }

  const { loginWithRedirect } = useAuth0();
  return (
    <button onClick={() => loginWithRedirect()}>Log In</button>
  );
  {/* <div>
      <label className="switch">
        <span>Signup?</span>
        <input type="checkbox" onChange={handleSignupCheckbox}/>
      </label>
      <form style={formStyles} onSubmit={handleSubmit}>
        <label style={labelStyles}>
          <input
            style={inputStyles}
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="Username"
          />
        </label>
        <br />
        <label style={labelStyles}>
          <input
            style={inputStyles}
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Password"
          />
        </label>
        <br />
        <button style={buttonStyles} type="submit">{signUpChecked ? "Sign up" : "Login"}</button>
      </form>

    </div> */}
}

export default Login;
