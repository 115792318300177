import React, { useState, useEffect } from 'react';

const Card = ({ title, footer, cardTools, cardClasses = "", cardBodyClasses = "", children }) => {
    return (
        <div className={`card ${cardClasses}`}>
            <div className="card-header">
                <h3 className="card-title">{title}</h3>
                {/* {cardTools &&
                    <div className="card-tools">
                        <span className="badge badge-primary">{cardTools}</span>
                    </div>
                } */}
                {cardTools && {cardTools}}
            </div>
            <div className={`card-body ${cardBodyClasses}`}>
                {children}
            </div>
            {footer && <div className="card-footer">
                {footer}
            </div>}
        </div>
    );
}

export default Card