import React, { useEffect, useMemo, useState } from 'react';
import Card from '../../components/Card';
import useApi from '../../components/useApi';
import Select from 'react-select';
import Button from '../../components/elements/Button';
import { apiCallResponseFeedback } from '../../helper/ErrorHandler';
import { useNavigate } from "react-router-dom";
import Toast from '../../components/elements/Toast';

const DatasetCreateView = () => {
    const [datasetName, setDatasetName] = useState('');
    const [description, setDescription] = useState('');

    const requiredFields = {
        datasetName: datasetName,
    }

    let navigate = useNavigate();
    const restApi = useApi();

    useEffect(() => {
    }, [])

    // Remove is-invalid class from field when user starts typing

    const handleChangeDatasetName = (event) => {
        setDatasetName(event.target.value);
        event.target.classList.remove("is-invalid")
    }

    const checkRequiredFields = () => {
        for (let key of Object.keys(requiredFields)) {
            let field = requiredFields[key];
            console.log(key + ": " + field);
            if (!field || field === '') {
                return false;
            }
        }
        return true;
    }

    const markAllRequiredFields = () => {
        for (let fieldName of Object.keys(requiredFields)) {
            if (requiredFields[fieldName] === '') {
                document.getElementById(fieldName).classList.add('is-invalid');
            }
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        let isValid = checkRequiredFields();
        if (!isValid) {
            markAllRequiredFields()
            return;
        }
        restApi.createDataset(
            datasetName,
        )
        .then(response => apiCallResponseFeedback(response))
        .then((result) => {
            let dataset_id = result.dataset._id
            navigate(`/datasets/${dataset_id}`)
        })
        .catch(error => {
            // Show Toast
            Toast.fire({
                icon: 'error',
                title: 'Dataset not created',
                text: error.message
            })
        })
    }

    return (
        <div className="content-wrapper">
            <div className='content-header'>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1>Create a new dataset</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Card
                                    title="Create new Dataset"
                                    cardClasses='card-primary'>
                                    <div className="form-group">
                                        <label htmlFor="datasetName">Dataset Name</label>
                                        <input type="text" className="form-control" id="datasetName" placeholder="Enter Dataset Name" onChange={handleChangeDatasetName} />
                                    </div>
                                    <Button>Create</Button>
                                </Card>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}

export default DatasetCreateView;