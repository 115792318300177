import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import QueryCollection from '../components/QueryCollection';
import SimilarImageQueryResultList from '../components/SimilarImageQueryResultList';
import CollectionBatchImportSelector from '../components/CollectionBatchImportSelector';
// import './components/css/CollectionDetailView.css';
import Card from "../components/Card"
import useApi from '../components/useApi';
import CollectionContent from '../components/CollectionContent';
import FileUpload from '../components/Fileupload';
import ImportFromDataset from '../components/collections/ImportFromDataset';

const CollectionDetailView = () => {
  const { collection_id } = useParams();
  const [collection, setCollection] = useState({});
  const [contents, setContents] = useState([]);
  const [similarImages, setSimilarImages] = useState([]);
  const [queryImage, setQueryImage] = useState(null);
  const restApi = useApi();

  function changeQueryImage(image) {
    setQueryImage(image)
  }

  function showSimilarImages(images) {
    setSimilarImages(images)
  }

  function handleQueryResult(result) {
    showSimilarImages(result)
  }

  useEffect(() => {
    restApi.getCollection(collection_id)
    .then(response => response.json())
    .then(response => {
      setCollection(response.collection)
      setContents(response.content)
    })
    .catch(err => console.log(err))

  }, [collection_id]);

  return (
    <div className="content-wrapper">
      <div className='content-header'>
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{collection.name}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><Link to=".." relative='path'>Collections</Link></li>
                <li className="breadcrumb-item active">{collection._id}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <Card title={collection.name}>
                Nothing to see here
              </Card>
            </div>
            <div className="col-lg-6">
              <ImportFromDataset collectionId={collection_id} />
              {/* <Card title="Import new data">
                <CollectionBatchImportSelector collectionId={collection_id} />
              </Card> */}
            </div>
          </div>
          <div>
            {/* Card with statistics */}
            <div className="row d-none">
              <div className="col-lg-12">
                <Card title="Statistics - Coming Soon">
                  <div className="row">
                    <div className="col-md-4">
                      <h4>Queries per day</h4>
                      <p>{contents.length}</p>
                    </div>
                    <div className="col-md-4">
                      <h4>Number of images</h4>
                      <p>{contents.length}</p>
                    </div>
                    <div className="col-md-4">
                      <h4>More Statistics</h4>
                      <p>{contents.length}</p>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <div>
            <h2>Query collection</h2>
            <div>
              <Card>
                <div>
                  <QueryCollection
                    cb_results={handleQueryResult}
                    collection_id={collection_id}
                    setQueryImage={changeQueryImage}/>
                  {queryImage && <img height="224px" src={queryImage}></img>}
                </div>
              </Card>
            </div>
            <div className="queryResults">
              <SimilarImageQueryResultList images={similarImages} collection_id={collection_id} cb_query={handleQueryResult}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionDetailView;