import React, { useState, useEffect } from 'react';
import useApi from './useApi';

function S3Image({ fileId, url, classes, ...other }) {
    const [tempUrl, setTempUrl] = useState(null);
    const { getTempUrl } = useApi();

    useEffect(() => {
        // async function fetchTempUrl() {
        //     const response = await fetch(`http://localhost:3001/collections/files/${fileId}`);
        //     const tempUrl = await response.json();
        //     setTempUrl(tempUrl.url);
        // }
        // fetchTempUrl();
        // getTempUrl(fileId)
        // .then((response) => response.json())
        // .then((result) => {
        //     setTempUrl(result.url)
        // })
    }, [fileId]);

    return (
        <div>
            {url ? (
                <img src={url} className={classes} {...other} alt="S3 File" />
            ) : (
                <p>Image not supported</p>
            )}
        </div>
    );
}

export default S3Image