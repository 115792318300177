import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import JobCard from '../components/jobs/JobCard';

const Dashboard = () => {
    const nav = useNavigate();

    return (
        <div className="content-wrapper">
            <div className="content-header"><h1>Jobs</h1></div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row col-xl-4">
                        <div className="col-md-12">
                            <JobCard numProcessedObjects={2550} numFoundObjects={11750} jobSource={"S3"} jobStatus="started" jobId="7ab3"></JobCard>
                        </div>
                        <div className="col-md-12">
                            <JobCard numProcessedObjects={0} numFoundObjects={0} jobSource={"S3"} jobStatus="queued" jobId="7ab4"></JobCard>
                        </div>
                        <div className="col-md-12">
                            <JobCard numProcessedObjects={2550} numFoundObjects={11750} jobSource={"S3"} jobStatus="queued" jobId="28f1"></JobCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
