import React, { useEffect, useMemo, useState } from 'react';
import Card from '../components/Card';
import useApi from '../components/useApi';
import Select from 'react-select';
import Button from '../components/elements/Button';
import { apiCallResponseFeedback } from '../helper/ErrorHandler';
import { useNavigate } from "react-router-dom";
import Toast from '../components/elements/Toast';

const CollectionCreateView = () => {
    const [collectionName, setCollectionName] = useState('');
    const [modelMethod, setModelMethod] = useState('');
    const [model, setModel] = useState('');
    const [indexType, setIndexType] = useState('');
    const [indexMetric, setIndexMetric] = useState('');
    const [indexParams, setIndexParams] = useState({nlist: 128});
    const [inputType, setInputType] = useState({value: null, label: "Please select input type"});
    const [inputTypeSelected, setInputTypeSelected] = useState(false);
    const [description, setDescription] = useState('');

    const requiredFields = {
        collectionName: collectionName,
        model: model,
        inputType: inputType,
        indexType: indexType,
    }

    let navigate = useNavigate();
    const restApi = useApi();

    useEffect(() => {
        // Reset Selects when inputType changes

        //TODO: Definetly not good code. Should only reset model when inputType changes
        // should actually reset in another function
        setModel(null);
    }, [inputType])

    // Remove is-invalid class from field when user starts typing

    const handleChangeCollectionName = (event) => {
        setCollectionName(event.target.value);
        event.target.classList.remove("is-invalid")
    }

    const handleChangeModel = (event) => {
        setModel(event.target.value);
        event.target.classList.remove("is-invalid")
    }

    const checkRequiredFields = () => {
        for(let key of Object.keys(requiredFields)) {
            let field = requiredFields[key];
            console.log(key + ": " + field);
            if (!field || field === '') {
                return false;
            }
        }
        return true;
    }

    const markAllRequiredFields = () => {
        for(let fieldName of Object.keys(requiredFields)) {
            if (requiredFields[fieldName] === '') {
                document.getElementById(fieldName).classList.add('is-invalid');
            }
        }
        // if (collectionName === '') {
        //     document.getElementById('collectionName').classList.add('is-invalid');
        // }
        // if (model === '') {
        //     document.getElementById('modelMethod').classList.add('is-invalid');
        // }
        // if (inputType === '') {
        //     document.getElementById('inputType').classList.add('is-invalid');
        // }
    }
        

    function handleSubmit(event) {
        event.preventDefault();
        // Check if all fields are filled
        let isValid = checkRequiredFields();
        console.log("isValid: " + isValid);
        if (!isValid) {
            markAllRequiredFields()
            return;
        }
        restApi.createCollection(
            collectionName,
            model.value,
            indexType.value,
            "COSINE",
            indexParams,
        )
            .then(response => apiCallResponseFeedback(response))
            .then((result) => {
                let collection_id = result.collection._id
                navigate(`/collections/${collection_id}`)
            })
            .catch(error => {
                // Show Toast
                Toast.fire({
                    icon: 'error',
                    title: 'Collection not created',
                    text: error.message
                })
            })
    }

    const inputTypeOptions = [
        { value: 'image', label: 'Vectorize Images' },
        { value: 'video', label: 'Vectorize Videos', disabled: false},
        { value: 'audio', label: 'Vectorize Audio', disabled: true },
        { value: 'text', label: 'Vectorize Text', disabled: true },
        { value: 'text_qna', label: 'Question and Answer', disabled: true },
    ]

    const modelOptionsByFiletype = {
        image: [
            { value: "cnn.vgg16", label: "VGG16"},
            { value: "cnn.resnet50", label: "ResNet50", disabled: true },
            { value: "cnn.resnet152", label: "ResNet152", disabled: true },
            { value: "cnn.inception_v3", label: "InceptionV3", disabled: true },
            { value: "cnn.mobilenet_v2", label: "MobileNetV2", disabled: true },
        ],
        video: [
            { value: "cnn.i3d", label: "I3D" },
            { value: "cnn.r2plus1d", label: "R2Plus1D", disabled: true },
            { value: "cnn.slowfast", label: "SlowFast", disabled: true },
        ],
    }

    const modelOptions = useMemo(() => {
        return modelOptionsByFiletype[inputType?.value] || [{ value: null, label: "Please select model" }]
    });

    const indexOptions = [
        { value: "IVF_FLAT", label: "Inverted Flat Index" },
        { value: "HNSW", label: "Hierarchical Navigable Small World" },
    ]

    const SelectModel = <Select
        id="model"
        key={`my_unique_select_key__${model}`}
        value={model || ''}
        options={modelOptions}
        isOptionDisabled={(option) => option.disabled}
        onChange={setModel}>
    </Select>

    const SelectInputType = <Select
        id='inputType'
        key={`my_unique_select_key__${inputType}`}
        value={inputType}
        options={inputTypeOptions}
        isOptionDisabled={(option) => option.disabled}
        onChange={setInputType}>
    </Select>

    const SelectIndexType = <Select
        id='indexType'
        key={`my_unique_select_key__${indexType}`}
        value={indexType}
        options={indexOptions}
        isOptionDisabled={(option) => option.disabled}
        onChange={setIndexType}>
    </Select>

    // const ModelMethodSelection = <Select
    //     options={modelMethodOptions}
    //     // defaultValue={modelMethodOptions[0]}
    //     onChange={setModelMethod}>
    // </Select>




    return (
        <div className="content-wrapper">
            <div className='content-header'>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1>Create a new collection</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Card
                                    title="Create new Collection"
                                    cardClasses='card-primary'
                                    >
                                        <div className="form-group">
                                            <label htmlFor="collectionName">Collection Name</label>
                                        <input type="text" className="form-control" id="collectionName" placeholder="Enter Collection Name" onChange={handleChangeCollectionName} />
                                        </div>


                                </Card>
                            </div>
                            <div className="col-lg-6">
                                <Card
                                    title="Collection Options"
                                    cardClasses='card-secondary'
                                >
                                    <div className="form-group">
                                        <label htmlFor="inputType">What do you want to do?</label>
                                        {SelectInputType}
                                    </div>
                                    {inputType &&
                                        <div className="form-group">
                                            <label>Select your model</label>
                                            {SelectModel}
                                        </div>
                                    }

                                    {model &&
                                        <div className="form-group">
                                            <label>Select Index</label>
                                            {SelectIndexType}
                                        </div>
                                    }

                                    {model && indexType &&
                                        <Button>Create</Button>
                                    }

                                </Card>
                            </div>
                        </div>
                    </form>
                </div>


                {/* <form onSubmit={handleSubmit}>
                    <label>
                        Collection name:
                        <input type="text" value={collectionName} onChange={} />
                    </label>
                    <button type="submit">Create collection</button>
                </form> */}
            </div>
        </div>
    );

}

export default CollectionCreateView;