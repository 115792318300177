import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/CollectionsList.css';
import { useAuth0 } from "@auth0/auth0-react";
import useApi from './useApi';
import Card from './Card';
import Toast from '../components/elements/Toast'

const DatasetsList = ({datasets, deleteAction}) => {
    return (
        <Card title="Datasets" cardClasses="" cardBodyClasses="p-0">
            <table className="table table-striped projects">
                <thead>
                    <tr>
                        <th style={{ width: '1%' }}>
                            #
                        </th>
                        <th style={{ width: '20%' }}>
                            Collection Name
                        </th>
                        <th style={{ width: '10%' }}>
                            No. Objects
                        </th>
                        <th style={{ width: '39%' }}>
                            Description
                        </th>
                        <th style={{ width: '30%' }}>
                            {/* Actions */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {datasets.map((dataset) => (
                        <tr>
                            <td>
                                #
                            </td>
                            <td>
                                {dataset.name}
                                <br />
                                <small>Created: {dataset.created_at}</small>
                            </td>
                            <td>
                                {dataset.no_objects || 0}
                            </td>
                            <td>
                                {dataset.description || "Description not available"}
                            </td>
                            <td className="project-actions text-right">
                                <Link className="btn btn-primary btn-sm" to={`/datasets/${dataset._id}`}>
                                    <i className="fas fa-folder">
                                    </i>
                                    &nbsp; View
                                </Link>
                                &nbsp;
                                <Link className="btn btn-info btn-sm" to={`/datasets/${dataset._id}/edit`}>
                                    <i className="fas fa-pencil-alt">
                                    </i>
                                    &nbsp; Edit
                                </Link>
                                &nbsp;
                                <button className="btn btn-danger btn-sm" onClick={() => deleteAction(dataset._id)}>
                                    <i className="fas fa-trash">
                                    </i>
                                    &nbsp; Delete
                                </button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    );
};

export default DatasetsList;