import React from 'react';

function Button({children, onClick, ...other}) {
    return (
        <button onClick={onClick} className="btn btn-block btn-outline-primary" {...other}>
            {children}
        </button>
    );
}

export default Button;