import React, { useState, useEffect } from 'react';
import useApi from '../useApi';
import { apiCallResponseFeedback } from '../../helper/ErrorHandler';

const QueryCollectionByVectorIdButton = ({ collection_id, vector_id, cb_query }) => {
    const restApi = useApi();

    function doQuery(event) {
        restApi.queryCollectionByVectorId(collection_id, vector_id)
            .then(response => apiCallResponseFeedback(response))
            .then((result) => {
                cb_query(result.hits)
            })
    }

    return (
        <button type="button" className="btn btn-primary" onClick={doQuery}>Query this image</button>
    );
}

export default QueryCollectionByVectorIdButton