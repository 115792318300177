// import Button from "../elements/Button";
import React, { useEffect, useState } from 'react';
import useApi from "../useApi";
import Card from "../Card"

function ImportFromDataset({ collectionId }) {
    const [selection, setSelection] = useState('');

    const [datasets, setDatasets] = useState([]);
    const [dataset, setDataset] = useState(null);

    
    const api = useApi();

    const getDatasets = () => {
        api.getDatasets()
            .then(response => response.json())
            .then(response => {
                setDatasets(response.datasets);
            })
            .catch(err => console.log(err));
    };

    const handleImport = (dataset) => {
        console.log(dataset);
        api.importDataset(collectionId, dataset._id)
            .then(response => response.json())
            .then(response => {
                console.log(response);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        getDatasets();
    }, []);

    // Render the component
    return (
        <Card title="Import Dataset" cardClasses="" cardBodyClasses="p-0">
            <table className="table table-striped projects">
                <thead>
                    <tr>
                        <th style={{ width: '1%' }}>
                            #
                        </th>
                        <th style={{ width: '20%' }}>
                            Dataset Name
                        </th>
                        <th style={{ width: '10%' }}>
                            No. Objects
                        </th>
                        <th style={{ width: '39%' }}>
                            Description
                        </th>
                        <th style={{ width: '30%' }}>
                            {/* Actions */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {datasets.map((dataset) => (
                        <tr>
                            <td>
                                #
                            </td>
                            <td>
                                {dataset.name}
                                <br />
                                <small>Created: {dataset.created_at}</small>
                            </td>
                            <td>
                                {dataset.no_objects || 0}
                            </td>
                            <td>
                                {dataset.description || "Description not available"}
                            </td>
                            <td className="project-actions text-right">
                                <button className="btn btn-info btn-sm" onClick={() => handleImport(dataset)}>
                                    <i className="fas fa-file-import">
                                    </i>
                                    &nbsp; Import
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    );
}

function GCSComponent() {
    return <div>GCS Component</div>;
}

function UploadComponent() {
    return <div>Upload Component</div>;
}

export default ImportFromDataset;