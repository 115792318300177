
const apiCallResponseFeedback = async (response) => {
    var body = await response.json()
    if(response.status >= 400) {
        var message = "Something went wrong"
        if(body.message) {
            message = body.message
        }
        throw new Error(message)
    }
    return body
}

export { apiCallResponseFeedback }