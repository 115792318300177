import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from './Button'

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    const handleClick = () => loginWithRedirect();
    return (
        <Button onClick={handleClick}>
            <i className="nav-icon fas fa-sign-in-alt" ></i> Log in
        </Button >
    );
};

export default LoginButton;