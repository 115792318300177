import React, { useState, useEffect } from 'react';

function SelectCollections({onSelect}) {
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');

  useEffect(() => {
    async function fetchCollections() {
      const response = await fetch('http://localhost:3001/collections');
      const data = await response.json();
      setCollections(data.collections);
    }
    fetchCollections();
  }, []);

  function handleChange(event) {
    setSelectedCollection(event.target.value);
    onSelect(event.target.value);
  }

  return (
    <select value={selectedCollection} onChange={handleChange}>
      <option value="">Select a collection</option>
      {collections.map((collection) => (
        <option key={collection._id} value={collection._id}>
          {collection.name}
        </option>
      ))}
    </select>
  );
}

export default SelectCollections;