import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import S3Image from './S3Image';
import useApi from './useApi';
import Card from './Card';
import QueryCollectionByVectorIdButton from './vector_query/QueryCollectionByVectorIdButton';
function LightBoxListTemplate({ image, index, collection_id, vector_id, setSelectedIndex, cb_query }) {
    return (
        <div className="col-md-4 col-4">
            <Card title={index + 1} cardClasses="m-1">
                {image} <br />
                <QueryCollectionByVectorIdButton collection_id={collection_id} vector_id={vector_id} cb_query={cb_query} />
            </Card>
        </div>
    )
}

const SimilarImageQueryResultList = ({images, collection_id, cb_query}) => {
    const [tempUrls, setTempUrls] = useState([]);
    const { getTempUrls } = useApi();
    const [showImages, setShowImages] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [imagesPerPage] = useState(30);
    useEffect(() => {
        let uuids = []
        let start = (pageNumber - 1) * imagesPerPage;
        let end = start + imagesPerPage;
        let _images = images.slice(start, end);
        for (let i = 0; i < _images.length; i++) {
            let image = _images[i];
            let uuid = image.entity.uuid
            uuids.push(uuid)
        }
        getTempUrls(uuids, collection_id)
        .then((response) => response.json())
        .then((result) => {
            for (let i = 0; i < result.urls.length; i++) {
                let url = result.urls[i];
                if (url == null) {
                    result.urls[i] = "https://via.placeholder.com/150"
                }
                _images[i].url = result.urls[i]
            }
            // setTempUrls(result.urls)
            setShowImages(_images)
        })
    }, [pageNumber, images]);
    
    return (
        <div>
            <div className="row no-gutters">
                {showImages?.map((image, index) => <LightBoxListTemplate key={index} collection_id={collection_id} vector_id={image.id} image={<S3Image classes="m-auto d-block" fileId={image.entity.uuid} url={image.url} />} index={index} cb_query={cb_query}/>)}
            </div>
            {/* {selectedIndex >= 0 && <Modal images={images} selectedImg={images[selectedIndex]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />} */}
            {/* <Modal images={images} selectedImg={images[selectedIndex]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} /> */}
        </div>
    )


};

export default SimilarImageQueryResultList;