import React, { useEffect, useState } from 'react';
import Layout from './Layout'
import "admin-lte/build/scss/adminlte.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./components/css/Toast.css";
import "./App.scss";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  document.body.classList.add("layout-fixed");
  document.body.classList.add("sidebar-mini");

  

  // useEffect(() => {
  //   const script_adminlte = document.createElement("script");
  //   // script.src = "/js/adminlte.min.js";
  //   script_adminlte.src = "https://cdn.jsdelivr.net/npm/admin-lte@3.1/dist/js/adminlte.min.js";
  //   script_adminlte.async = false;

  //   const script_jquery = document.createElement("script");
  //   // script.src = "/js/adminlte.min.js";
  //   script_jquery.src = "https://code.jquery.com/jquery-3.7.1.min.js";
  //   script_jquery.async = false;


  //   document.body.appendChild(script_jquery);
  //   document.body.appendChild(script_adminlte);

  //   return () => {
  //     document.body.removeChild(script_adminlte);
  //     document.body.removeChild(script_jquery);
  //   }

  // }, [])

  // useEffect(() => {
  // }, [isLoading, isAuthenticated])

  return (
    <div className="App wrapper">
      <div className="preloader flex-column justify-content-center align-items-center" style={{height: isLoading ? "100%" : "0px"}}>
        <p>Just one second...</p>
        <img src="/preloader-2.gif" alt="AdminLTELogo" height="60" width="60" style={{visibility: isLoading ? "visible" : "hidden"}} />
      </div>
      {/* {!loading && <Layout />} */}
      <Layout />
    </div>
  );
}

export default App;
