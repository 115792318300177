import React, { useEffect, useState } from 'react';
import SelectCollections from '../SelectCollections';
import useApi from './useApi';
import { apiCallResponseFeedback } from '../helper/ErrorHandler';
import Toast from '../components/elements/Toast'

const QueryCollection = ({cb_results, collection_id, setQueryImage}) => {
  const [file, setFile] = useState(null)
  const [base64, setBase64] = useState(null)


  useEffect(() => {
    if (file) {
      getBase64(file).then(_base64 => {
        updateBase64(_base64)
      });
    }
  }, [file]);

  const updateBase64 = (_base64) => {
    setBase64(_base64)
    setQueryImage(_base64)
  }

  const getBase64 = (_file) => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(_file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  }
  const { queryCollectionByUpload } = useApi();

  const sendImage = (event) => {
    event.preventDefault()

    queryCollectionByUpload(collection_id, file)
      .then(response => apiCallResponseFeedback(response))
      .then((result) => {
        cb_results(result.hits)
        // handle the response from the server
      })
      .catch((error) => {
        Toast.fire({
          icon: 'error',
          title: 'Query failed',
          text: error.message
        })
        // handle the error
      });
  }

  return (
    <form onSubmit={sendImage}>
      <input type="file" name="image" onChange={handleChange} />
      <button type="submit">Upload</button>
      {/* {base64 && <img height="224px" src={base64} />} */}
    </form>
  );
}

export default QueryCollection;