import { Outlet, Navigate } from "react-router-dom";
import { React, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedRoute = () => {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    if(isLoading) {
        return <div></div>
    }
    if (isAuthenticated) {
        return <Outlet />;
    } else {
        loginWithRedirect();
    }
};

export default ProtectedRoute;