import React, { useState, useEffect } from 'react';
import DatasetsList from '../../components/DatasetsList';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import useApi from '../../components/useApi';


function DatasetsListView({showPublic = false, title="Your Datasets"}) {
    const [collectionName, setCollectionName] = useState('');
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const restApi = useApi();

    const [datasets, setDatasets] = useState([]);
    useEffect(() => {
        if(showPublic) {
            restApi.getPublicDatasets()
                .then(response => response.json())
                .then(response => {
                    setDatasets(response.datasets)
                });
        } else {
            restApi.getDatasets()
                .then(response => response.json())
                .then(response => {
                    setDatasets(response.datasets)
                });
        }
    }, [isLoading]);

    if (isLoading) {
        return (
            <div className="loading">Loading</div>
        )
    }
    return (
        <div className="content-wrapper">
            <div className='content-header'>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <DatasetsList datasets={datasets} />
                { !showPublic &&
                    <div className="col-sm-12 justify-content-end d-flex">
                        <ol>
                            {/* Button to create new collection with a plus icon */}
                            <Link to="/datasets/create">
                                <button type="button" className="btn btn-dark btn-circle shadow"><i className="fas fa-plus"></i></button>
                            </Link>
                        </ol>
                    </div>

                }
            </div>
        </div>
    );

}

export default DatasetsListView;